/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none !important;
}

/* Optional: Customize .focus-visible */
.focus-visible {
  @apply outline-none focus:ring !important;
}

input.focus-visible {
  @apply outline-none focus:border-black focus:ring-0 !important;
}

textarea.focus-visible {
  @apply outline-none focus:border-black focus:ring-0 !important;
}

input[type='checkbox'].focus-visible {
  @apply outline-none focus:ring !important;
}

input.toggle.focus-visible + .block {
  @apply ring !important;
}

input.radio.focus-visible + .label {
  @apply ring !important;
}
