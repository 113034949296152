.th:first-child > div > div {
  @apply rounded-l-base;
}

.th:last-child > div > div {
  @apply rounded-r-base;
}

.card-th:first-child > div > div {
  @apply rounded-tl-base;
}

.card-th:last-child > div > div {
  @apply rounded-tr-base pr-8;
}

@-moz-document url-prefix() {
  tr {
    height: 1px !important;
  }
  td,
  th {
    height: 100% !important;
  }
}
