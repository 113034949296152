.animate-from-top {
  transition: opacity 150ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(0, -20px, 0);
}

[data-enter] > .animate-from-top {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
