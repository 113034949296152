@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

@import './defaults/datepicker';
@import './defaults/focus';
@import './defaults/font';
@import './defaults/input';
@import './defaults/modal';
@import './defaults/popover';
@import './defaults/table';
@import './defaults/toggle';
@import './transitions';

html,
body,
#__next {
  height: 100%;
  font-size: 16px;
  font-weight: 300;
}

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.pulsate {
  animation: pulsate-bck 0.5s ease-in-out both;
}

.no-scrollbar {
  scrollbar-width: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
