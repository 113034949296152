.toggle ~ .dot {
  @apply bg-gray-400;
}

.toggle:checked ~ .dot {
  transform: translateX(150%);
  @apply bg-white;
}

.toggle ~ .toggle-bg {
  @apply border-gray-200 bg-white;
}

.toggle:checked ~ .toggle-bg {
  @apply border-green-500 bg-green-500;
}

.toggle:disabled ~ .dot {
  @apply opacity-50;
}

.toggle:disabled ~ .toggle-bg {
  @apply opacity-50;
}
