.input-container label {
  @apply translate-y-[50%] scale-[1.3] text-black antialiased;
  transition: 233ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition-property: transform, color;
  will-change: transform;
}

.input-container:focus-within label,
.input-container label.filled {
  @apply transform-none text-gray-900;
}

textarea,
input[type='text'] {
  -webkit-appearance: none;
}
