.transition-next-enter {
  opacity: 0.01;
  transform: translateX(100px);
}

.transition-next-enter-active {
  opacity: 1;
  transition: all 200ms;
  transform: translateX(0px);
}

.transition-next-exit {
  opacity: 1;
  transform: translateX(0px);
}

.transition-next-exit-active {
  opacity: 0.01;
  transform: translateX(-100px);
  transition: all 200ms;
}

.transition-prev-enter {
  opacity: 0.01;
  transform: translateX(-100px);
}

.transition-prev-enter-active {
  opacity: 1;
  transition: all 200ms;
  transform: translateX(0px);
}

.transition-prev-exit {
  opacity: 1;
  transform: translateX(0px);
}

.transition-prev-exit-active {
  opacity: 0.01;
  transform: translateX(100px);
  transition: all 200ms;
}
