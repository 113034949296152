.modal-dialog {
  transform-origin: top;
  transition: opacity 150ms ease-in-out, transform 250ms ease-in-out;
  transform: translate3d(0, -20px, 0);
}

.modal-dialog[data-enter] {
  opacity: 1;
  transform: none;
}

.backdrop {
  @apply flex flex-col items-center;
  @apply fixed top-0 left-0 z-50 h-full w-full;
  @apply opacity-0 transition-opacity duration-150 ease-in-out;
}

.backdrop[data-enter] {
  @apply opacity-100;
}

.modal-dialog-from-right {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.modal-dialog-from-right[data-enter] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.modal-dialog-from-left {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.modal-dialog-from-left[data-enter] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
