.react-datepicker {
  @apply border-gray-200 font-poppins;
}

.react-datepicker__header {
  @apply border-primary-500 bg-primary-500;
}

.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker__time-name {
  @apply text-white;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  @apply bg-green-100 text-white;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: rgba(12, 38, 51, 1);
}
